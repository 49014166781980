import { AlertType } from './../shared/models/alertType';
import { Injectable } from '@angular/core';
import { Assessment } from '../shared/models/assessment';
import { ConfigService } from '../services/config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError, retry, shareReplay } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactType } from '../shared/models/contactType';
import { ContactRecipientType } from '../shared/models/contact-recipient-type';

@Injectable({
  providedIn: 'root'
})
export class FSContactsService {

  constructor(private httpClient: HttpClient,
    private snackBar: MatSnackBar,
    private configService: ConfigService
  ) { }

  public GetContactTypes() {
    return this.httpClient.get<Array<ContactType>>(`${this.configService.apiUrl}/FieldSupervisors/GetContactTypes`).pipe(
        retry(3),
        catchError(error => {
            console.log(error);
            return EMPTY;
        }),
        shareReplay()
    );
  }

  public GetAlertTypes() {
    return this.httpClient.get<Array<AlertType>>(`${this.configService.apiUrl}/FieldSupervisors/GetAlertTypes`).pipe(
        retry(3),
        catchError(error => {
            console.log(error);
            return EMPTY;
        }),
        shareReplay()
    );
  }

  public GetContactRecipientTypes() {
    return this.httpClient.get<Array<ContactRecipientType>>(`${this.configService.apiUrl}/recipient-types`).pipe(
        retry(3),
        catchError(error => {
            console.log(error);
            return EMPTY;
        })
    );
  }

  public createInternContacts(requestPayload: any): Observable<any> {
    return this.httpClient.post<any>(`${this.configService.apiUrl}/v1.0/intern-contacts`, requestPayload).pipe(
      catchError(error => {
        console.log(error);
        return EMPTY;
      })
    );
  }

}
