import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FSContactsService } from 'src/app/services/fs-contacts.service';
import { AlertType } from '../models/alertType';
import { ContactRecipientType } from '../models/contact-recipient-type';
import { ContactType } from '../models/contactType';
import { ContactTypeEnum } from '../enums/contact-type.enum';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { FieldSupervisor } from '../models/fsupervisor.model';
import { SelectedIntern } from '../models/selected-intern.model';
import { InternNoteService } from 'src/app/services/intern-note.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmailRecipient } from '../models/email-recipient';
import { EmailModel } from '../models/email-model';
import { Observable } from 'rxjs/internal/Observable';
import { EmailService } from 'src/app/services/email.service';
import { ObsAlertTypeEnum } from '../enums/obs-alert-type.enum';
import { finalize } from 'rxjs/internal/operators/finalize';
import { formatDate } from '@angular/common';
import { ContactService } from 'src/app/services/contact.service';
import { NewContact } from '../models/new-contact.model';

@Component({
  selector: 'app-new-contact',
  templateUrl: './new-contact.component.html',
  styleUrls: ['./new-contact.component.scss']
})
export class NewContactComponent implements OnInit {
  selectedInterns: SelectedIntern[] = [];
  contactForm: FormGroup;
  alertTypeList: AlertType[];
  contactRecipientTypeList: ContactRecipientType[];
  contactTypeList: ContactType[];
  contactRecipientTypesListLoaded: boolean;
  alertTypeListLoaded: boolean;
  contactTypeListLoaded: boolean;
  fieldSupervisor: FieldSupervisor;
  private token = '';
  newContact: NewContact;
  private RECIPIENT_EMAIL_ADDRESS = 'classroomsupport@teachersoftomorrow.org';
  private RECIPIENT_NAME = 'Classroom Support'
  private TX_CC_EMAIL_ADDRESS = 'c&c@texasteachers.org';
  private CERTIFICATION_EMAIL_ADDRESS = 'certification@teachersoftomorrow.org';
  private NOREPLY_EMAIL_ADDRESS = 'noreply@teachersoftomorrow.org';

  constructor(
    public dialogRef: MatDialogRef<NewContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectedIntern[],
    private fb: FormBuilder,
    private fsContactsService: FSContactsService,
    private authService: AuthService,
    private router: Router,
    private globalService: GlobalService,
    private internNoteService: InternNoteService,
    private snackBar: MatSnackBar,
    private emailService: EmailService,
    private contactService: ContactService
  ) {
    this.selectedInterns = data;
  }

  ngOnInit(): void {
    if (!this.authService.authenticated) {
      this.router.navigate(['auth']);
    }

    if (this.authService.authenticated) {
      if (this.globalService.supervisor != undefined) {
        this.fieldSupervisor = this.globalService.supervisor;
      }
    }

    this.authService.getAccessToken().then(token => {
      this.token = token;
    });

    this.contactForm = this.fb.group({
      contactDate: [new Date(), Validators.required],
      contactRecipients: [[], Validators.required],
      contactType: ['', Validators.required],
      alertType: ['', Validators.required],
      details: ['', Validators.required]
    });

    this.initializeDropdowns();
  }

  initializeDropdowns() {
    // initialize alerts type dropdown
    this.fsContactsService.GetAlertTypes().subscribe(data => {
      this.alertTypeList = data;
      this.alertTypeListLoaded = true;
    });

    // initialize contact recipient types dropdown
    this.fsContactsService.GetContactRecipientTypes().subscribe(data => {
      this.contactRecipientTypeList = data;
      this.contactRecipientTypesListLoaded = true;
    });

    // initialize contact types dropdown
    this.fsContactsService.GetContactTypes().subscribe(data => {
      const allowedContactTypes = [ContactTypeEnum.Email, ContactTypeEnum.Phone, ContactTypeEnum.VideoConference, ContactTypeEnum.InPerson, ContactTypeEnum.TextMessage];
      this.contactTypeList = data.filter(type => allowedContactTypes.includes(type.id));
      this.contactTypeListLoaded = true;
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  openSnackBar(data: string, status: string) {
    this.snackBar.open(data, status, {
      duration: 5 * 1500,
    });
  }

  onSubmit(): void {
    if (this.contactForm.valid) {
      this.newContact = {
        contactType: this.contactForm.value.contactType,
        alertType: this.contactForm.value.alertType,
        contactRecipients: this.contactForm.value.contactRecipients,
        contactDate: this.contactForm.value.contactDate,
        details: this.contactForm.value.details
      }
      this.contactService.onSubmit(this.newContact, this.selectedInterns, this.fieldSupervisor, this.token);
    } 
  }

  
}