export enum ContactTypeEnum {
    None = 0,
    Observation = 1,
    Email = 2,
    Phone = 3,
    Administration = 4,
    ThreeWeekInitialContact = 5,
    VideoConference = 6,
    InPerson = 7,
    MeetAndGreet = 8,
    InformalObservation = 9,
    Conference = 10,
    TextMessage = 11
}
