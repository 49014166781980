import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { AppConfigurationClient, GetConfigurationSettingResponse } from '@azure/app-configuration';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

  constructor(private configSvc: ConfigService) { }

  async getFeatureFlag(featureName: string) {
    let client = new AppConfigurationClient(this.configSvc.configuration.appConfigurationConnectionString);
    const environment = this.configSvc.configuration.environment;
    const featureFlagKey = `.appconfig.featureflag/${featureName}`;
    const options = environment !== 'Production' ? { key: featureFlagKey, label: `${environment}` } : { key: featureFlagKey };
    const flag: GetConfigurationSettingResponse = await client.getConfigurationSetting(options, {
      requestOptions: {
        customHeaders: { 'cache-control': 'no-cache' }
      }
    });
    return <boolean>JSON.parse(<string>flag.value).enabled;
  }
}
