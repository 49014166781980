export class certificate {
    certProgramID: number = null;
    certType: string;
    profileId: number = null;
    internNumber: number = null;
    lastName: string;
    middleName: string;
    firstName: string;
    reverseFullName: string;
    emailAddress: string;
    campusId: number = null;
    campusName: string;
    campusZipCode: number = null;
    districtId: number = null;
    districtName: string;
    assigned: Date;
    trackStart: Date;
    completedDate: Date;
    initialContact: Date;
    initialContactCompleted: Date;
    //1st Obs
    firstObsDeadLine: Date;
    firstObsCompletedDate: Date;
    firstObsEndDate: Date;
    firstObsStatusCode: string;
    firstObsStatus: string;
    //2nd Obs
    secondObsDeadLine: Date;
    secondObsCompletedDate: Date;
    secondObsEndDate: Date;
    secondObsStatusCode: string;
    secondObsStatus: string;
    //3rd Obs
    thirdObsDeadLine: Date;
    thirdObsCompletedDate: Date;
    thirdObsEndDate: Date;
    thirdObsStatusCode: string;
    thirdObsStatus: string;
    //4th Obs
    forthObsDeadLine: Date;
    forthObsCompletedDate: Date;
    fourthObsEndDate: Date;
    forthObsStatusCode: string;
    fourthObsStatus: string;
    //5th Obs
    fifthObsDeadLine: Date;
    fifthObsCompletedDate: Date;
    fifthObsEndDate: Date;
    fifthObsStatusCode: string;
    fifthObsStatus: string;
    //6th Obs
    sixthObsDeadLine: Date;
    sixthObsCompletedDate: Date;
    sixthObsEndDate: Date;
    sixthObsStatusCode: string;
    sixthObsStatus: string;
    allObsAreCompleted: boolean;
    recommendationStatus: string;
    principalRecommendationStatus: string;
    admittedDate: Date;
    isActive: boolean;
    isLateHire: boolean;
    isExpiringWithinTwentyWorkingDays: boolean;

    //7th Obs
    seventhObsDeadLine: Date;
    seventhObsCompletedDate: Date;
    seventhObsEndDate: Date;
    seventhObsStatusCode: string;
    seventhObsStatus: string;

    isDistrictSelect: boolean;
    stateName: string;
}
