import { contactObservation } from './contactObservation';
import { contactAlert } from './contactAlert';
import { formatDate } from '@angular/common';

export class Assessment {
  CertProgramId: Number = 0;
  AssessmentId: number = 0;
  AssessmentTypeId: Number = 0;
  ContactTypeId: Number = 0;
  CoachId: number;
  CoachName: string;
  ContactInfo: string;
  Date: string = formatDate(new Date().toString(), 'yyyy-MM-dd', 'en');
  StartTime: string = "0";
  EndTime: string = "0";
  OverallRating: Number;
  Alert: contactAlert = new contactAlert();
  observation: contactObservation = new contactObservation();
  InternIdentifier: Number = 0;
  InternFullName: string;
  Author: string;
  AuthorEmail: string;
  ProfileIdentifier: Number = 0;
  StatusId: number;
  CampusId: number;
  contactRecipientIds: number[] = [];
  CreateUser: string;
  otherRecipientDetail: string;
}
