import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { formatDate } from '@angular/common';

import { MatSnackBar } from '@angular/material/snack-bar';
import { InternNoteService } from './intern-note.service';
import { EmailService } from './email.service';
import { SelectedIntern } from '../shared/models/selected-intern.model';
import { ObsAlertTypeEnum } from '../shared/enums/obs-alert-type.enum';
import { EmailRecipient } from '../shared/models/email-recipient';
import { EmailModel } from '../shared/models/email-model';
import { FSContactsService } from './fs-contacts.service';
import { FieldSupervisor } from '../shared/models/fsupervisor.model';
import { FormGroup } from '@angular/forms';
import { NewContact } from '../shared/models/new-contact.model';

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    private RECIPIENT_EMAIL_ADDRESS = 'classroomsupport@teachersoftomorrow.org';
    private RECIPIENT_NAME = 'Classroom Support'
    private TX_CC_EMAIL_ADDRESS = 'c&c@texasteachers.org';
    private CERTIFICATION_EMAIL_ADDRESS = 'certification@teachersoftomorrow.org';
    private NOREPLY_EMAIL_ADDRESS = 'noreply@teachersoftomorrow.org';

    constructor(
        private fsContactsService: FSContactsService,
        private internNoteService: InternNoteService,
        private emailService: EmailService,
        private snackBar: MatSnackBar
    ) { }

    onSubmit(newContact: NewContact, selectedInterns: SelectedIntern[], fieldSupervisor: FieldSupervisor, token: string): void {
        const internContactRequest = {
            selectedInterns: selectedInterns,
            assessmentData: {
                Alert: {
                    AlertTypeId: newContact.alertType,
                    Description: newContact.details
                },
                AssessmentId: 0,
                AssessmentTypeId: 0,
                Author: fieldSupervisor.displayName,
                AuthorEmail: fieldSupervisor.PrimaryEmail,
                CampusId: 0,
                CertProgramId: 0,
                CoachId: fieldSupervisor.FieldSupervisorId,
                ContactTypeId: newContact.contactType,
                CreateUser: fieldSupervisor.displayName,
                Date: formatDate(newContact.contactDate.toString(), 'yyyy-MM-dd', 'en'),
                EndTime: "0",
                InternFullName: "",
                InternIdentifier: 0,
                ProfileIdentifier: 0,
                StartTime: "0",
                contactRecipientIds: newContact.contactRecipients,
                observation: {
                    contactObservation: {
                        ObservationIdentifier: "0",
                        Name: "",
                        StartTime: "0",
                        EndTime: "0",
                        MileageId: 0
                    }
                }
            }
        };

        this.fsContactsService.createInternContacts(internContactRequest).subscribe(response => {
            this.snackBar.open('Contact submitted successfully for intern(s)', 'Ok');
            this.sendEmails(newContact, selectedInterns, fieldSupervisor, token);
        }, error => {
            this.snackBar.open('Error submitting contact for intern(s)', 'Ok');
        });
    }

    saveAlertEmailNotes(newContact: NewContact, selectedInterns: SelectedIntern[], fieldSupervisor: any): void {

        let internNoteRequest = {
            selectedInterns: selectedInterns,
            candidateNoteDTO: {
                Note: newContact.details,
                Priority: false,
                CategoryId: 13,
                ProfileId: 0,
                CreateUser: fieldSupervisor.displayName,
                ModUser: null,
                AccountTypeId: 8
            }
        }
        this.internNoteService.createInternContactNotes(internNoteRequest).subscribe((response) => {
            this.snackBar.open("Notes saved successfully for intern(s)", 'Ok');
        }, error => {
            this.snackBar.open('Error saving note for intern(s)', 'Ok');
        });
    }

    sendEmails(newContact: NewContact, selectedInterns: SelectedIntern[], fieldSupervisor: any, token: string) {
        if (newContact.alertType) {
            this.sendAlertEmails(newContact, selectedInterns, fieldSupervisor, token).pipe(
                finalize(() => {
                    if (newContact.alertType == ObsAlertTypeEnum.LeaveResignOrTerminate) {
                        this.sendTerminationAlert(newContact, selectedInterns, fieldSupervisor, token);
                    }
                })
            )
                .subscribe({
                    next: (result) => this.snackBar.open("Email sent successfully", 'Okay'),
                    error: (error) => this.snackBar.open('Email failed to send', 'Error')
                });

            this.saveAlertEmailNotes(newContact, selectedInterns, fieldSupervisor);
        }
    }

    sendAlertEmails(newContact: NewContact, selectedInterns: SelectedIntern[], fieldSupervisor: any, token: string): Observable<EmailModel> {
        let sender: EmailRecipient = new EmailRecipient;
        let destination: EmailRecipient = new EmailRecipient;

        //Send standard/default Alert Email   
        destination.email = this.RECIPIENT_EMAIL_ADDRESS;
        destination.name = this.RECIPIENT_NAME;

        sender.email = fieldSupervisor.PrimaryEmail;
        sender.name = `${fieldSupervisor.FirstName} ${fieldSupervisor.LastName}`;

        let subject = ` Alert: ${this.getAlertName(newContact.alertType)} from Field Supervisor ${fieldSupervisor.FirstName} ${fieldSupervisor.LastName}`;

        //Generate the Email Model (Subject, Sender, etc.)
        let newMailData = this.getEmailData(newContact, selectedInterns, fieldSupervisor, sender, destination, subject);

        //validate if there is a valid token to send the email
        if (token.length > 0) {
            //Calling service to send emails
            return this.emailService.SendEmail(newMailData, token);
        }
    }

    buildAlertEmailHTML(newContact: NewContact, intern: SelectedIntern, fieldSupervisor: any) {
        const alertName = this.getAlertName(newContact.alertType);
        let html = `<p>Field Supervisor: ${fieldSupervisor.FirstName} ${fieldSupervisor.LastName} (${fieldSupervisor.PrimaryEmail})</p>`;
        html += `<p>Intern: ${intern.firstName} ${intern.lastName} (${intern.email})</p>`;
        html += `<p>Alert: ${alertName}</p>`;
        html += `<p>${newContact.details}</p>`;

        return html;
    }

    private sendTerminationAlert(newContact: NewContact, selectedInterns: SelectedIntern[], fieldSupervisor: any, token: string) {
        let sender: EmailRecipient = new EmailRecipient;
        let destination: EmailRecipient = new EmailRecipient;

        //Generate email model specifics by Alert Type
        destination.email = this.CERTIFICATION_EMAIL_ADDRESS;

        sender.email = this.NOREPLY_EMAIL_ADDRESS;
        sender.sendOnBehalf = true;

        let subject = `Field Supervisor Portal - Leave, Resign or Terminate Alert`;

        let terminationEmailAlertData = this.getEmailData(newContact, selectedInterns, fieldSupervisor, sender, destination, subject, true);

        //validate if there is a valid token to send the email
        if (token.length > 0) {
            //Calling service to send emails
            this.emailService.SendEmail(terminationEmailAlertData, token).subscribe();
        }
    }

    private getEmailData(newContact: NewContact, selectedInterns: SelectedIntern[], fieldSupervisor: any, senderEmail: EmailRecipient, destinationEmail: EmailRecipient, subject: string, isTerminationEmail: boolean = false): EmailModel[] {
        const emails: EmailModel[] = [];
        selectedInterns.forEach(intern => {
            if (isTerminationEmail && intern.stateName.toLowerCase() === 'texas') {
                destinationEmail.email = this.TX_CC_EMAIL_ADDRESS;
            }
            const emailData = new EmailModel();
            emailData.to.push(destinationEmail);
            emailData.from = senderEmail;
            emailData.subject = subject;
            emailData.htmlContent = isTerminationEmail ? this.buildLeaveResignTerminateEmailHTML(newContact, intern, fieldSupervisor) : this.buildAlertEmailHTML(newContact, intern, fieldSupervisor);
            emailData.allRecipients = [];
            emailData.saveToSentFolder = true;

            emails.push(emailData);
        });

        return emails;
    }

    buildLeaveResignTerminateEmailHTML(newContact: NewContact, intern: SelectedIntern, fieldSupervisor: any) {
        let html = `<p>The following was submitted from Field Supervisor Portal by: ${fieldSupervisor.FirstName} ${fieldSupervisor.LastName} (${fieldSupervisor.PrimaryEmail})</p>`;
        html += `<ul>`;
        html += `<li>Intern ID: ${intern.internId}</li>`;
        html += `<li>Intern Name: ${intern.firstName} ${intern.lastName}</li>`;
        html += `<li>Contact Date: ${newContact.contactDate}</li>`;
        html += `<li>Comment: ${newContact.details}</li>`;
        html += `</ul>`;

        return html;
    }

    private getAlertName(typeId: number): string {
        switch (typeId) {
            case ObsAlertTypeEnum.QualityIssueWithIntern:
                return "Quality issue with intern";
            case ObsAlertTypeEnum.BragAboutThisIntern:
                return "Brag about this intern";
            case ObsAlertTypeEnum.SafetyConcern:
                return "Safety concern";
            case ObsAlertTypeEnum.Miscellaneous:
                return "Miscellaneous";
            case ObsAlertTypeEnum.LeaveResignOrTerminate:
                return "Leave, Resign or Terminate";
            case ObsAlertTypeEnum.Collaboration:
                return "Collaboration";
            default:
                return "Invalid Alert Type";
        }
    }
}