import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, retry, shareReplay } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';
import { certificate } from '../shared/models/certificate';
import { Checklist } from '../shared/models/checklist.model';
import { internCertDetails } from '../shared/models/internCertDetails';
import { MentorObservation } from '../shared/models/mentor-observation.model';
import { recommendation } from '../shared/models/recommendation';
import { State } from '../shared/models/state.model';
import { InternCampusAdministrators } from '../shared/models/intern-campus-administrators.model';

@Injectable({
  providedIn: 'root'
})
export class InternService {

  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private httpClient: HttpClient, private configService: ConfigService) { }

  public getInternByZipcode(zipcode: string, miles: string, campusIds: string) {
    return this.httpClient.get<[]>(`${this.configService.apiUrl}/v1.0/GetInternCertsByDistance?zipCode=${zipcode}&miles=${miles}&campusIds=${campusIds}`).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public getInternDetailsById(internID: string, fieldSupervisorID: number) {
    return this.httpClient.get<internCertDetails>(`${this.configService.apiUrl}/v1.0/Certificates/Details?InternID=${internID}&FieldSupervisorID=${fieldSupervisorID}`).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public getSelectedInternDetails(internID: string) {
    return this.httpClient.get<internCertDetails[]>(`${this.configService.apiUrl}/v1.0/Certificates/${internID}`).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }


  public getCertificatesBySupervisor(id: number) {

    return this.httpClient.get<certificate[]>(`${this.configService.apiUrl}/v1.0/Certificates/FieldSupervisor/${id}`).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public getSupervisorCertifiedStates(id: number) {
    return this.httpClient.get<State[]>(`${this.configService.apiUrl}/v1.0/FieldSupervisor/Certify/State/${id}`).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public AddRecommendation(rcmd: recommendation) {

    console.log('attempting to call post with id:' + rcmd.CertProgramId);
    return this.httpClient.post<recommendation>(`${this.configService.apiUrl}/v1.0/CertRecommendation/`, rcmd).pipe(
      catchError(error => {
        console.log(error);
        return EMPTY;
      })
    );
  }

  public downloadFromURL(downloadURL: string){
    this.headers = this.headers.set('Accept', 'application/pdf');
    return this.httpClient.get<any>(downloadURL, { headers: this.headers, responseType: 'blob' as 'json' });
  }

  public DownloadObservation(obsAssessmentId: number) {
    console.log("In Service method to get PDF");
    return this.httpClient.get<any>(`${this.configService.apiUrl}/v1.0/GetObsAssessmentPDF/${obsAssessmentId}`).pipe(
      catchError(error => {
        console.log(error);
        return EMPTY;
      })
    );
  }

  public getWhatsNextData(internId: string) {
    return this.httpClient.get<Checklist[]>(`${this.configService.apiUrl}/v1.0/checklist/internwebsite/${internId}`).pipe(
      catchError(error => {
        console.log(error);
        return EMPTY;
      })
    );
  }

  public getObservations(profileId: number) {
    return this.httpClient.get<MentorObservation[]>(`${this.configService.apiUrl}/v1.0/Mentors/Observations/${profileId}`);
  }

  public downloadMentorObservationDocument(documentId: number) {
    let requestHeaders: HttpHeaders = null;
    return this.httpClient.get<any>(`${this.configService.apiUrl}/file/mentor-observation-document/${documentId}`, { headers: requestHeaders, responseType: 'blob' as 'json' });
  }

  public getInternCampusAdministrators(internId: string) {
    return this.httpClient.get<InternCampusAdministrators>(`${this.configService.apiUrl}/v1.0/intern/${internId}/campus-administrators`).pipe(
      catchError(error => {
        console.log(error);
        return EMPTY;
      })
    );
  }

}
