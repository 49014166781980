import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import { MatChipInputEvent} from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AuthService } from '../../services/auth.service';
import { EmailService } from '../../services/email.service';
import { FileAttachment } from '../models/attachment';
import { CommunicationLog } from '../models/communication-log';
import { EmailModel } from '../models/email-model';
import { EmailRecipient } from '../models/email-recipient';
import { FieldSupervisor } from '../models/fsupervisor.model';
import { AlertType } from '../models/alertType';
import { FSContactsService } from 'src/app/services/fs-contacts.service';
import { FormBuilder, Validators } from '@angular/forms';
import { InternService } from 'src/app/services/intern.service';
import { InternCampusAdministrators } from '../models/intern-campus-administrators.model';
import { NewContact } from '../models/new-contact.model';
import { RecipientTypeEnum } from '../enums/recipient-type.enum';
import { ContactTypeEnum } from '../enums/contact-type.enum';
import { SelectedIntern } from '../models/selected-intern.model';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  recipientCtrl = new UntypedFormControl();
  filteredRecipients: EmailRecipient[];
  supervisor: FieldSupervisor;
  mailData: EmailModel;
  token: string;
  log: CommunicationLog[] = [];

  @ViewChild('recipientInput') recipientInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  subject = new UntypedFormControl('');
  priority = new UntypedFormControl();
  emailContent = new UntypedFormControl('');
  htmlContent = '';
  fileInput: any;
  invalidAttachment = false;
  maxFileSizeAllowed = 50000000;
  currentfileSize: number = 0;
  invalidFileSize = false;
  attachments = [];
  fileList = '';
  maxFileList = '';
  alertTypeList: AlertType[] = [];
  alertForm: FormGroup;
  principalCtrl = new UntypedFormControl(false);
  mentorCtrl = new UntypedFormControl(false);
  campusSupervisorCtrl = new UntypedFormControl(false);
  internCampusAdministrators: InternCampusAdministrators;
  newContact: NewContact;
  selectedInterns: SelectedIntern[];
  fieldSupervisor: FieldSupervisor;

  constructor(public dialogRef: MatDialogRef<EmailComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private emailService: EmailService, private authService: AuthService, 
    private fsContactsService: FSContactsService, private fb: FormBuilder, 
    private internService: InternService, private contactService: ContactService) {
    //initialize mail data
    this.data.sent = false;
    this.mailData = data.mailModel;
    this.selectedInterns = data.selectedInterns;
    this.internCampusAdministrators = data.internCampusAdministrators;
    this.filteredRecipients = this.mailData.bcc;
    this.fieldSupervisor = data.fieldSupervisor;
  }

  ngOnInit() {
    //get auth token from authService
    this.authService.getAccessToken().then(token => {
      this.token = token;
    });

    //make a call to get intern campus administrators when a single intern is selected for the Email Selected Interns option
    if (this.filteredRecipients.length === 1) {
      if (this.internCampusAdministrators == null) {  
        this.internService.getInternCampusAdministrators(this.selectedInterns[0].internId).subscribe(data => {
          this.internCampusAdministrators = data;
        });
      }
    }
    this.alertForm = this.fb.group({
      alertType: [null, Validators.required],
      details: ['', [Validators.required, Validators.minLength(1)]]
    });

    this.fsContactsService.GetAlertTypes().subscribe(data => {
      this.alertTypeList = data;
    });
  }

  close() {
    this.dialogRef.close();
  }

  add(event: MatChipInputEvent): void {
    // Add recipient only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add a recipient
      if ((value || '').trim()) {
        //this.recipients.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.recipientCtrl.setValue(null);
    }
  }

  remove(recipient: EmailRecipient): void {
    const index = this.mailData.allRecipients.indexOf(recipient);
    if (index >= 0) {
      this.mailData.allRecipients.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    //this.recipients.push(event.option.viewValue);
    this.recipientInput.nativeElement.value = '';
    this.recipientCtrl.setValue(null);
  }

  private _filter(value: EmailRecipient): EmailRecipient[] {
    const filterValue = value.name.toLowerCase();

    return this.mailData.allRecipients.filter(recipient => recipient.name.toLowerCase().indexOf(filterValue) === 0);
  }

  public async sendEmails() {

    // Check if the alert form is valid
    if (this.alertForm.invalid) {
      // Mark all controls as touched to trigger validation messages
      this.alertForm.markAllAsTouched();
      return;
    }

    let self = this;

    let newMailData = new EmailModel;
    newMailData.bcc = this.mailData.bcc;
    newMailData.cc = this.mailData.cc;
    newMailData.body = this.mailData.body;

    //Populate data from
    newMailData.subject = this.subject.value;
    newMailData.htmlContent = this.htmlContent;
    newMailData.highImportance = this.priority.value;
    newMailData.allRecipients = [];

    newMailData.saveToSentFolder = true;
    newMailData.processReplacements = true;
    newMailData.emailPerReceipient = true;

    //Add Attachments
    if (this.attachments != null && this.attachments.length > 0) {
      newMailData.hasAttachments = true;
      newMailData.attachments = [];
      for (var i = 0; i < self.attachments.length; i++) {

        let fileSource = self.attachments[i];

        let attachment = new FileAttachment();
        attachment.size = fileSource.size;
        attachment.contentType = fileSource.type;

        attachment.contentBytes = await this.readUploadedFileAsText(fileSource);

        attachment.name = fileSource.name;
        attachment.lastModifiedDateTime = fileSource.lastModifiedDate;
        attachment.contentId = fileSource.name;
        newMailData.attachments.push(attachment);
      }
    }

    if (this.principalCtrl.value) {
      // Add principal to the email if the checkbox is checked and the principal email is not null
      if (this.internCampusAdministrators?.principalEmailAddress != null) {
        var principalEmailRecipient = new EmailRecipient();
        principalEmailRecipient.email = this.internCampusAdministrators.principalEmailAddress;
        principalEmailRecipient.name = this.internCampusAdministrators.principalName;
        principalEmailRecipient.profileId = this.internCampusAdministrators.profileId;
        if (!newMailData.bcc.some(recipient => recipient.email === principalEmailRecipient.email)) {
          newMailData.bcc.push(principalEmailRecipient);
        }
      }
    }

    if (this.mentorCtrl.value) {
      // Add mentor to the email if the checkbox is checked and the mentor email is not null
      if (this.internCampusAdministrators?.mentorEmailAddress != null) {
        var mentorEmailRecipient = new EmailRecipient();
        mentorEmailRecipient.email = this.internCampusAdministrators.mentorEmailAddress;
        mentorEmailRecipient.name = this.internCampusAdministrators.mentorName;
        mentorEmailRecipient.profileId = this.internCampusAdministrators.profileId;
        if (!newMailData.bcc.some(recipient => recipient.email === mentorEmailRecipient.email)) {
          newMailData.bcc.push(mentorEmailRecipient);
        }
      }
    }

    if (this.campusSupervisorCtrl.value) {
      // Add campus supervisor to the email if the checkbox is checked and the campus supervisor email is not null
      if (this.internCampusAdministrators?.campusSupervisorEmailAddress != null) {
        var campusSupervisorEmailRecipient = new EmailRecipient();
        campusSupervisorEmailRecipient.email = this.internCampusAdministrators.campusSupervisorEmailAddress;
        campusSupervisorEmailRecipient.name = this.internCampusAdministrators.campusSupervisorName;
        campusSupervisorEmailRecipient.profileId = this.internCampusAdministrators.profileId;
        if (!newMailData.bcc.some(recipient => recipient.email === campusSupervisorEmailRecipient.email)) {
          newMailData.bcc.push(campusSupervisorEmailRecipient);
        }
      }
    }

    //validate if there is a valid token to send the email
    if (this.token.length > 0) {
      //Calling service to send emails      
      this.emailService.SendEmail(newMailData, this.token).subscribe(data => {
        this.createNewContact();
        this.dialogRef.close(data);
      });
    }
  }

  createNewContact() {
    let contactRecipients = [];
    contactRecipients.push(RecipientTypeEnum.Intern);
    if (this.mentorCtrl.value && this.internCampusAdministrators?.mentorEmailAddress != null) {
      contactRecipients.push(RecipientTypeEnum.Mentor);
    }
    if (this.principalCtrl.value && this.internCampusAdministrators?.principalEmailAddress != null) {
      contactRecipients.push(RecipientTypeEnum.Principal);
    }
    if (this.campusSupervisorCtrl.value && this.internCampusAdministrators?.campusSupervisorEmailAddress != null) {
      contactRecipients.push(RecipientTypeEnum.CampusSupervisor);
    }

    this.newContact = {
      contactType: ContactTypeEnum.Email,
      alertType: this.alertForm.value.alertType,
      contactRecipients: contactRecipients,
      contactDate: new Date(),
      details: this.alertForm.value.details
    }
    this.contactService.onSubmit(this.newContact, this.selectedInterns, this.fieldSupervisor, this.token);
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '150px',
    minHeight: '100px',
    maxHeight: '150px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: 'arial',
    defaultFontSize: '2',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    uploadWithCredentials: true,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript'
      ],
      [
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ],

  };

  public fileSelectedChange(event: any) {
    this.currentfileSize = 0;
    this.invalidAttachment = false;
    this.invalidFileSize = false;
    this.fileInput = event.target;   

    this.validateFilesSize(this.fileInput.files);

    if (this.attachments.length > 4) {
      this.invalidAttachment = true;
    }
  }

  private validateFilesSize(files: any) {    

    for (var i = 0; i < files.length; i++) {

      this.attachments.push(files[i]);     

      this.currentfileSize = this.currentfileSize + files[i].size;

      if (this.attachments.length > 4) {
        this.fileList = `${this.maxFileList} ( ${(this.attachments.length - 4)} ) files more...`;
      }
      else if (this.attachments.length == 4) {
        this.fileList = this.fileList + files[i].name + ' | ';
        this.maxFileList = this.fileList;
      } else {
        this.fileList = this.fileList + files[i].name + ' | ';
      }

      if (files[i].size > this.maxFileSizeAllowed) {
        this.invalidFileSize = true;
      }
    }

    if (this.currentfileSize >= this.maxFileSizeAllowed) {
      this.invalidFileSize = true;
    }   
  }

  private readUploadedFileAsText = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        var b64 = temporaryFileReader.result.toString().replace(/^data:.+;base64,/, '');
        resolve(b64);
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };
}
